import { useSnackbar } from "notistack";
import React, { useEffect } from "react";

const Footer = () => {
  const [footerStatic, setFooterStatic] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleCopyURL = async () => {
    navigator.clipboard.writeText("LarsFL");
    enqueueSnackbar("Discord name copied", { variant: "success" });
  };

  useEffect(() => {
    if (typeof window && document) {
      setFooterStatic(window.innerHeight > document.body.scrollHeight ? false : true);
    }
  });

  return (
    <footer
      className="footer footer-center p-4 bg-base-100 text-base-content bottom-0"
      style={{ position: footerStatic ? "static" : "fixed" }}>
      <aside>
        <p>
          Copyright © 2024 - by <span onClick={handleCopyURL}>LarsFL</span>
        </p>
      </aside>
    </footer>
  );
};

export default Footer;
